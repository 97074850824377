import React from "react";

export default function Logo({ colorA, colorB, width, height }) {
  return (
    <svg
      viewBox="0 0 640 640"
      xmlns="http://www.w3.org/2000/svg"
      width={width || "1em"}
      height={height || "1em"}
    >
      <defs>
        <path
          id="a"
          d="M524.35 533.97c-81.46-.07-132.37-.11-152.74-.13-18.04-.02-32.66-14.66-32.64-32.7.01-17.71.05-61.97.11-132.79 76.79.07 124.78.11 143.97.13 22.89.02 41.43 18.59 41.41 41.48-.02 16.53-.05 57.87-.11 124.01z"
        />
        <path
          id="b"
          d="M327.98 533.01c-72.75.51-118.22.83-136.41.96-27.06.19-49.14-21.59-49.33-48.64-.38-54.15-1.33-189.53-2.86-406.14 77.56-.55 126.03-.89 145.41-1.03 22.09-.15 40.12 17.63 40.28 39.72.39 55.35 1.36 193.73 2.91 415.13z"
        />
      </defs>
      <path
        d="M524.35 533.97c-81.46-.07-132.37-.11-152.74-.13-18.04-.02-32.66-14.66-32.64-32.7.01-17.71.05-61.97.11-132.79 76.79.07 124.78.11 143.97.13 22.89.02 41.43 18.59 41.41 41.48-.02 16.53-.05 57.87-.11 124.01z"
        fill={colorB || "#00204d"}
      />
      <path
        d="M327.98 533.01c-72.75.51-118.22.83-136.41.96-27.06.19-49.14-21.59-49.33-48.64-.38-54.15-1.33-189.53-2.86-406.14 77.56-.55 126.03-.89 145.41-1.03 22.09-.15 40.12 17.63 40.28 39.72.39 55.35 1.36 193.73 2.91 415.13z"
        fill={colorA || "#0060e8"}
      />
    </svg>
  );
}
